*{
    margin: 0;
    padding: 0;
    font-size: 20px;
    text-decoration: none;
}

.nav{
    height: 50px;
    background-color: aqua;
    display: flex;
    align-items: center;
}
.nav li{
    float: left;
    list-style: none;
    padding-left: 50px;
    
}
.nav li p{
    text-decoration: none;
    font-size: 50px;
}